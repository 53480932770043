import React, { useState } from 'react'
import { Link, NavLink, Routes, Route } from 'react-router-dom'
import styles from './Contact.module.scss'
import styles2 from './App.module.scss'
import { useNavigate, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'
import Terms from './Terms'
import Privacy from './Privacy'
import Footer from './Footer'
import WhoAreWe from './images/whoarewe.png'
const About = () => {
  document.title = 'About us - Willendorf Gallery'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery aims to collect, preserve and display prehistoric artefacts and fossils, making
    them accessible for a broad audience through artistically mounted objects, and acquainting people
    with our distant past, hence protecting our natural and human heritance.`
  )

  const navigate = useNavigate()
  const match = useMatch('/:a/:page')
  const page = match?.params?.page

  return (
    <div className={styles2.wrapper}>
      <div className={styles2.columns}>
        <span>
          <Routes>
            <Route
              exact
              path=""
              element={
                <div>
                  <Title>Who are we?</Title>
                  <p>
                    Willendorf Gallery was created by Philip Joosen and Sam Van Rompaey as the result of a mutual
                    interest in Natural History and Archaeology.
                  </p>
                  <p>
                    Over time a concept emerged to pursue a representative <Link to="/collection">collection</Link>,
                    bridging the timeframe where early man and their natural surroundings created the foundation that
                    ultimately led to the Neolithic Revolution and the rise of civilizations.
                  </p>
                  <p>We chose the iconic Venus of Willendorf as personification of this marvelous journey.</p>
                  <h3>Mission</h3>
                  <p>
                    Willendorf Gallery aims to collect, preserve and display prehistoric artefacts and fossils, making
                    them accessible for a broad audience through artistically mounted objects, and acquainting people
                    with our distant past, hence protecting our natural and human heritance.
                  </p>
                  <p>
                    <img src={WhoAreWe} style={{ width: '100%', marginTop: 20 }} />
                  </p>
                </div>
              }></Route>
            <Route path="terms" element={<Terms />}></Route>
            <Route path="privacy" element={<Privacy />}></Route>
          </Routes>
        </span>
        <span className={styles2.desktop}>
          <Title>About</Title>
          <NavLink to="" activeClassName={styles2.activeLink} className={styles2.link}>
            About Willendorf Gallery
          </NavLink>
          <NavLink to="terms" activeClassName={styles2.activeLink} className={styles2.link}>
            Terms &amp; Conditions
          </NavLink>
          <NavLink to="privacy" activeClassName={styles2.activeLink} className={styles2.link}>
            Privacy Statement
          </NavLink>
        </span>
      </div>
    </div>
  )
}

export default About
