import React, { useState } from 'react'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import styles from './App.module.scss'
import instagram from './images/instagram.png'
import Venus from './images/venus2.svg'
import { Icon } from '@blueprintjs/core'
import Gallery from './Gallery'
const Home = (props) => {
  let navigate = useNavigate()
  document.title =
    'Willendorf Gallery - Dedicated to the raw, natural beauty of Paleolithic and Neolithic Artefacts &amp; Fossils'
  document.querySelector('meta[name="description"]').setAttribute(
    'content',
    `Willendorf Gallery aims to collect, preserve and display prehistoric artefacts and fossils, making
      them accessible for sale to a broad audience through artistically mounted objects, and acquainting people
      with our distant past, hence protecting our natural and human heritance.`
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      {!props.comingSoon && (
        <>
          <div className={styles.titletext} onClick={() => navigate('/collection/all')}>
            <div>
              <img src={Venus} style={{ width: 100 }} />
            </div>
            Dedicated to the raw, natural beauty of Paleolithic and Neolithic Artefacts &amp; Fossils
          </div>
        </>
      )}
      {props.comingSoon && (
        <div className={styles.titletext} style={{ fontSize: 50, marginTop: -60, marginBottom: -90 }}>
          Coming Soon
        </div>
      )}
    </div>
  )
}

export default Home
