import React, { useState, useEffect } from 'react'
import styles from './Sidebar.module.scss'
import { Drawer, Button } from '@blueprintjs/core'

import styles2 from './App.module.scss'
import ContactForm from 'ContactForm'
import { NavLink, useNavigate, Route, Routes, useMatch, BrowserRouter as Router } from 'react-router-dom'
import Title from './Title'

const Sidebar = (props) => {
  const navigate = useNavigate()
  const nav = (link) => {
    props.setIsOpen(false)
    navigate(link)
  }
  const match = useMatch('/:page/*')

  const page = match?.params?.page
  const id = match?.params?.id
  return (
    <Drawer isOpen={props.open} size={'80%'}>
      <div>
        <div style={{ display: 'flex', margin: 0 }}>
          <span style={{ flex: 1, margin: 15, marginRight: 0 }}>
            <div style={{ fontSize: 30, letterSpacing: 2 }}>
              <div>Willendorf</div>
              <div
                style={{
                  fontSize: '0.5em',
                  float: 'left',
                  fontWeight: 'bold',
                  width: 220,
                  textAlign: 'right',
                }}
                className={styles.subtitle}>
                Gallery
              </div>
            </div>
          </span>
          <Button
            minimal
            icon="cross"
            large
            onClick={() => props.setIsOpen(false)}
            style={{ flex: 0, marginRight: 15, marginTop: 20, alignSelf: 'start' }}
          />
        </div>
        <div style={{ margin: 15, fontSize: 13 }} onClick={() => props.setIsOpen(false)}>
          <NavLink to="collection/all">
            <h3>Collection</h3>
          </NavLink>
          <NavLink to="gallery/all">
            <h3>Gallery</h3>
          </NavLink>
          {false && (
            <NavLink to="interiors">
              <h3>Interiors</h3>
            </NavLink>
          )}
          <NavLink to="services">
            <h3>Services</h3>
          </NavLink>
          <NavLink to="services/education" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Education</h4>
          </NavLink>
          <NavLink to="services/taxation" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Determination &amp; Taxation</h4>
          </NavLink>
          <NavLink to="services/preservation" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Preservation &amp; Display</h4>
          </NavLink>
          <NavLink to="services/consignment" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Consign exclusive fossils</h4>
          </NavLink>
          <NavLink to="about">
            <h3>About</h3>
          </NavLink>
          <NavLink to="about" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Who are we?</h4>
          </NavLink>
          <NavLink to="about/terms" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Terms &amp; Conditions</h4>
          </NavLink>
          <NavLink to="about/privacy" activeClassName={styles2.activeLink}>
            <h4 className={styles.sublink}>Privacy Statement</h4>
          </NavLink>
          <NavLink to="contact">
            <h3>Contact</h3>
          </NavLink>
        </div>
      </div>
    </Drawer>
  )
}

export default Sidebar
